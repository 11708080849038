require('../../../../bower_components/flexslider/jquery.flexslider.js');

FlexSlider = (function(){

    var $el, controlNav, directionNav, animation, autoSlide, pauseOnHover, carousel, itemWidth, itemMargin, startAt;

    function FlexSlider(el, controlNav, directionNav, animation, autoSlide, pauseOnHover, carousel, itemWidth, itemMargin, startAt) {
        _.bindAll(this, 'init'); 

        selector = el || '.flexslider';

        this.$el = $(selector);
        this.controlNav = (typeof controlNav === "undefined" || controlNav === null) ? false : controlNav;
        this.directionNav = (typeof directionNav === "undefined" || directionNav === null) ? false : directionNav;
        this.animation = animation || "slide";
        this.autoSlide =  (typeof autoSlide === "undefined" || autoSlide === null) ? false : autoSlide;
        this.pauseOnHover = (typeof pauseOnHover === "undefined" || pauseOnHover === null) ? true : pauseOnHover;
        
        this.carousel = (typeof carousel === "undefined" || carousel === null) ? false : carousel;
        this.itemWidth = (typeof itemWidth === "undefined" || itemWidth === null) ? 150 : itemWidth;
        this.itemMargin = (typeof itemMargin === "undefined" || itemMargin === null) ? 15 : itemMargin;
        this.startAt = (typeof startAt === "undefined" || startAt === null) ? 0 : startAt;

        this.init();
    }

    FlexSlider.prototype.init = function() {
        if(this.carousel){
            $(this.$el).flexslider({
                animation: this.animation,
                slideshow: this.autoSlide,
                pauseOnHover: this.pauseOnHover,
                controlNav: this.controlNav,
                directionNav: this.directionNav,
                startAt: this.startAt,
                itemWidth: this.itemWidth,
                itemMargin: this.itemMargin
            });
        }else{
            $(this.$el).flexslider({
                animation: this.animation,
                slideshow: this.autoSlide,
                pauseOnHover: this.pauseOnHover,
                controlNav: this.controlNav,
                directionNav: this.directionNav,
                startAt: this.startAt
            });            
        }
        
    };

    return FlexSlider;

})();