$(window).load(function(){
    //if you need some foundation js functionality
    require('../../../bower_components/foundation-sites/dist/js/foundation.js');
    
    require('./classes/Validate.js');
    //Validation EXAMPLE:
    new Validate(['#contact-form']);
    
    require('./classes/FlexSlider.js');
    //Flexslider EXAMPLE:
    new FlexSlider('#home-slider',false);
    new FlexSlider('#brands .flexslider',false,true,"slide",true,true,true);
    
    $(document).foundation();

    $('#sidebar-button').on('click',function(e){
        //$('#sidebar-categories').toggle();
        $('#sidebar-categories').toggleClass('reveal');
        $('#sidebar-button i').toggleClass('fa-chevron-down');
        $('#sidebar-button i').toggleClass('fa-chevron-up');
        $("html, body").animate({ scrollTop: 0}, "slow");
    });

    $('#order .select-color').on('change',function(e){
        var color = $(this).val();
        var product = $('#order #product').val();
        var $select_size = $(this).parent('td').parent('tr').find('.select-size');
        var url = $('#order form').data('ajax');
        $.ajax({
            url: url,
            data: {
                'product': product,
                'color': color
            },
            success: function(data){
                var options = $select_size.find('option').get(0).outerHTML;
                $.each(data,function(w,v){
                    options += "<option value=\""+v.id+"\">"+v.code+"</option>";
                });
                $select_size.html(options);
            },
            dataType: 'json'
        });
    });

    $('#order .select-colorfr').on('change',function(e){
        var color = $(this).val();
        var product = $('#order #product').val();
        var url = $('#order form').data('ajax');
        $.ajax({
            url: url,
            data: {
                'product': product,
                'color': color
            },
            success: function(data){
                $('#colorsizes').html(data);
            },
            dataType: 'html'
        });
    });

    $('#shoppingcart .update').hide();
    $('#shoppingcart .qty').on('change keyup',function(e){
        var $current = $(this);
        var $price = $current.parent('td').parent('tr').find('.total-price');
        var $table = $current.parent('td').parent('tr').parent('tbody').parent('table');
        var $transport = $table.find('#transport');
        var $subtotal = $table.find('#subtotal');
        var $vat = $table.find('#vat');
        var $total = $table.find('#total');

        var transporttot = parseFloat($table.data('transporttot'));
        var transport = parseFloat($table.data('transport'));
        var vat = parseFloat($table.data('vat'));

        var product = $current.data('product');
        var amount = $current.val();
        var url = $('#shoppingcart').parent('form').data('ajax');
                    
        var val = {};
        val[product] = amount;
        $.ajax({
            url: url,
            data: {
                qty: val
            },
            success: function(data){
                if(amount>data){
                    $current.val(data);
                }
                $price.find('.qty').html(data);
                $price.find('.total').html((((data*$price.data('price')).toFixed(2)).toString()).replace(".", ","));
                var subtotal = 0;
                var total = 0;
                $('.subtotal').each(function(e){
                    subtotal += parseFloat($(this).html().replace(",", "."));
                });
                if(subtotal>transporttot){
                    $transport.hide();
                    total = subtotal;
                    $transport.find('.total').html((((0).toFixed(2)).toString()).replace(".", ","));
                }else{
                    $transport.show();
                    total = subtotal+transport;
                    $transport.find('.total').html(((transport.toFixed(2)).toString()).replace(".", ","));
                }
                $subtotal.find('.total').html(((total.toFixed(2)).toString()).replace(".", ","));
                totalvat = (total*vat).toFixed(2);
                $vat.find('.total').html((totalvat.toString()).replace(".", ","));
                total = parseFloat(total)+parseFloat(totalvat);
                $total.find('.total').html(((total.toFixed(2)).toString()).replace(".", ","));
            },
            dataType: 'json'
        });
    });

    var facturation = $('#delivery_address').parent('form').data('facturation');
    var delivery = $('#delivery_address').parent('form').data('delivery');
    var delivery_selector = '#address, #submit';
    $('.delivery_address').on('change', function(e){
        if($(this).val()=='collect'){
            $('#address').hide();
            $('#delivery_address #address input').val('');
            $('#delivery_address #address input:not(.neverrequired)').removeAttr('required').addClass('notrequired');
            $('#delivery_address #address select').removeAttr('required').addClass('notrequired');
            $('#delivery_address #address select option').removeAttr('selected');
        }else{
            $('#delivery_address #address .notrequired').attr('required',true);
            if($(this).val()=='new'){
                $(delivery_selector).show();
                $('#delivery_address #address input').val('');
                $('#delivery_address #address input').removeAttr('readonly');
                $('#delivery_address #address select option').removeAttr('selected');
                $('#delivery_address #address select').removeAttr('disabled');
            }else if($(this).val()==''){
                
                $(delivery_selector).hide();
                //haal facturatie adres op en vul in  
                $.ajax({
                    url: facturation,
                    success: function(data){
                        $('#delivery_address input[name="name"]').val(data.name);
                        $('#delivery_address input[name="address"]').val(data.address);
                        $('#delivery_address input[name="zip"]').val(data.zip);
                        $('#delivery_address input[name="city"]').val(data.city);
                        $('#delivery_address input[name="phone"]').val(data.phone);
                        $('#delivery_address input[name="fax"]').val(data.fax);
                        $('#delivery_address #address select[name="country_id"] option').removeAttr('selected');
                        $('#delivery_address #address select[name="country_id"] option[value="'+data.country_id+'"]').prop('selected', true);
                        $(delivery_selector).show();
                    },
                    dataType: 'json'
                });
                
                $('#delivery_address #address input').attr('readonly', true);
                $('#delivery_address #address select').attr('disabled', true);
            }else{
                id = $(this).val();
                
                $(delivery_selector).hide();
                //haal leverings adres op aan de hand van id 
                $.ajax({
                    url: delivery,
                    data: {
                        id: id
                    },
                    success: function(data){
                        $('#delivery_address input[name="name"]').val(data.name);
                        $('#delivery_address input[name="address"]').val(data.address);
                        $('#delivery_address input[name="zip"]').val(data.zip);
                        $('#delivery_address input[name="city"]').val(data.city);
                        $('#delivery_address input[name="phone"]').val(data.phone);
                        $('#delivery_address input[name="fax"]').val(data.fax);
                        $('#delivery_address #address select[name="country_id"] option').removeAttr('selected');
                        $('#delivery_address #address select[name="country_id"] option[value="'+data.country_id+'"]').prop('selected', true);
                        $(delivery_selector).show();
                    },
                    dataType: 'json'
                });
                
                $('#delivery_address #address input').removeAttr('readonly');
                $('#delivery_address #address select').removeAttr('disabled');
            }
        } 
    });
});