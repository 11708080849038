require('../../../../bower_components/jquery.inputmask/dist/jquery.inputmask.bundle.js');

Validate = (function(){
    function Validate(classes){
        _.bindAll(this, 'init');

        this.classes = classes;
        this.init();
    }

    Validate.prototype.init = function() {
        _.each(this.classes, this.validate);
    };

    Validate.prototype.validate = function(d){
        $.validate({
            borderColorOnError : '#a94442',
            language: myLanguage,
            form: d
        });
        // $('[name="phone"]').inputmask("099/99 99 99");
        // $('[name="fax"]').inputmask("099/99 99 99");
        // $('[name="cellphone"]').inputmask("0999/99 99 99");
    };

    return Validate;
})();